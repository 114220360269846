<template>
  <div class="box-status">
    <!-- index:main:isOpen START -->
    <span
      style="font-size: 24px; margin-top: 1.5rem;"
      v-html="'AppCheckin - SmsCheckin - CardCheckin - NfcCheckin'"
      v-if="boxOpened.isOpen && false"></span>

    <div class="description" v-if="boxOpened.isOpen">
      <!-- eslint-disable-next-line max-len -->
      <span v-html="$t(`outdoor.index.combi.headline.${box.hasAppCheckin}_${box.hasSmsCheckin}_${box.hasCardCheckin}_${box.hasNfcCheckin}`)"></span>
    </div>

    <div class="description" v-if="boxOpened.status === 'CLOSED'">
      <!-- eslint-disable-next-line max-len -->
      <span v-html="`<strong>Wir haben gerade geschlossen!</strong>Bitte besuchen Sie unswährend <br>unserer Öffnungszeiten:`"></span>
    </div>

    <div
      v-if="boxOpened.status === 'OPEN' && fakeHoursManned && fakeHoursManned.length > 0"
      class="opening-hours opened"
    >
      <div
        class="hours hours-headline"
        :key="`weekday-day--1`"
        v-if="fakeHours && fakeHours.length > 0 && fakeHoursManned && fakeHoursManned.length > 0"
      >
        Mit Verkaufspersonal
      </div>
      <div
        class="hours"
        v-for="(day, dayIndex) in fakeHoursManned"
        :key="`weekday-day-${dayIndex}`"
      >
        <span
          v-if="$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') === '00:00' &&
            $date(day.timeTo, 'HH:mm:ss').format('HH:mm') === '00:00'"
          v-html="`
          <span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
          <span class='without-employee'>Ohne Personal</span>`"
        ></span>

        <!-- eslint-disable-next-line max-len -->
        <span
          v-if="$date(day.timeFrom, 'HH:mm:ss').format('HH:mm') !== '00:00' &&
            $date(day.timeTo, 'HH:mm:ss').format('HH:mm') !== '00:00'"
          v-html="`
          <span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
          <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
            - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}</span>`"
        ></span>
      </div>
    </div>

    <div v-if="boxOpened.status === 'OPEN' && fakeHours && fakeHours.length > 0"
      :class="[
        'opening-hours opened',
        { 'opened-second' : fakeHoursManned && fakeHoursManned.length > 0 }
      ]"
    >
      <div
        class="hours hours-headline"
        :key="`weekday-day--1`"
        v-if="fakeHours && fakeHours.length > 0 && fakeHoursManned && fakeHoursManned.length > 0"
      >
        Ohne Verkaufspersonal
      </div>
      <div
        class="hours"
        v-for="(day, dayIndex) in fakeHours"
        :key="`weekday-day-${dayIndex}`"
      >
        <!-- eslint-disable-next-line max-len -->
        <span v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
          <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
            - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}</span>`"
          v-if="$date(day.timeTo, 'HH:mm:ss').format('HH:mm') !== '23:59'"
        ></span>
        <!-- eslint-disable-next-line max-len -->
        <span v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
          <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
            - 24:00</span>`"
          v-else
        ></span>
      </div>
    </div>

    <!-- eslint-disable-next-line max-len -->
    <div v-if="boxOpened.status === 'OPEN' && fakeHours && fakeHours.length === 0 && fakeHoursManned && fakeHoursManned.length === 0" class="opening-hours opened">
      <div
        class="hours"
        v-for="(day, dayIndex) in boxOpeningHoursSorted"
        :key="`weekday-day-${dayIndex}`"
      >
        <!-- eslint-disable-next-line max-len -->
        <span v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
          <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
            - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}</span>`"
        ></span>
      </div>
    </div>
    <!-- index:main:isOpen END -->

    <!-- index:main:isClosed START -->
    <div v-if="boxOpened.status === 'CLOSED' && fakeHoursManned && fakeHoursManned.length > 0"
      class="closed">
      <!-- eslint-disable-next-line max-len -->
      <div class="opening-hours">
        <div
          class="hours hours-headline"
          :key="`weekday-day--1`"
          v-if="fakeHours && fakeHours.length > 0 && fakeHoursManned && fakeHoursManned.length > 0"
        >
          Mit Verkaufspersonal
        </div>
        <div
          class="hours"
          v-for="(day, dayIndex) in fakeHoursManned"
          :key="`weekday-day-${dayIndex}`"
        >
          <!-- eslint-disable-next-line max-len -->
          <span v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
            <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
             - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}</span>`"
          ></span>
        </div>
      </div>
    </div>

    <div v-if="boxOpened.status === 'CLOSED' && fakeHours && fakeHours.length > 0"
      :class="[
        'closed',
        { 'closed-second' : fakeHoursManned && fakeHoursManned.length > 0 }
      ]"
    >
      <!-- eslint-disable-next-line max-len -->
      <div class="opening-hours">
        <div
          class="hours hours-headline"
          :key="`weekday-day--1`"
          v-if="fakeHours && fakeHours.length > 0 && fakeHoursManned && fakeHoursManned.length > 0"
        >
          Ohne Verkaufspersonal
        </div>
        <div
          class="hours"
          v-for="(day, dayIndex) in fakeHours"
          :key="`weekday-day-${dayIndex}`"
        >
          <!-- eslint-disable-next-line max-len -->
          <span v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
            <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
             - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}</span>`"
            v-if="$date(day.timeTo, 'HH:mm:ss').format('HH:mm') !== '23:59'"
          ></span>
          <!-- eslint-disable-next-line max-len -->
          <span v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
            <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
             - 24:00</span>`"
            v-else
          ></span>
        </div>
      </div>
    </div>

    <div class="closed" v-if="boxOpened.status === 'CLOSED'
        && fakeHours && fakeHours.length === 0 && fakeHoursManned && fakeHoursManned.length === 0">
      <!-- index:main:isClosed:times START -->
      <div class="opening-hours">
        <div
          class="hours"
          v-for="(day, dayIndex) in boxOpeningHoursSorted"
          :key="`weekday-day-${dayIndex}`"
        >
          <!-- eslint-disable-next-line max-len -->
          <span v-html="`<span class='weekday-name'>${weekdayName(day.weekday)}</span><span class='two-po'>:</span>
            <span class='ho'>${$date(day.timeFrom, 'HH:mm:ss').format('HH:mm')}
             - ${$date(day.timeTo, 'HH:mm:ss').format('HH:mm')}</span>`"
          ></span>
        </div>
      </div>
      <!-- index:main:isClosed:times END -->
    </div>

    <div class="closed" v-if="boxOpened.status !== 'OPEN' && boxOpened.status !== 'CLOSED'">
      <!-- index:main:isClosed:maintenance START -->
      <div
        class="maintenance"
        v-if="boxOpened.status === 'MAINTENANCE'"
      >
        <h4>CLOSED - Wartung</h4>
      </div>
      <!-- index:main:isClosed:maintenance END -->

      <!-- index:main:isClosed:disturbance START -->
      <div
        class="disturbance"
        v-if="boxOpened.status === 'DISTURBANCE'"
      >
        <h4>CLOSED - Störung</h4>
      </div>
      <!-- index:main:isClosed:disturbance END -->

    </div>
    <!-- index:main:isClosed END -->
  </div>
</template>

<script>
export default {
  name: 'box-status',
  props: {
    box: {
      type: Object,
    },
    fakeHours: {
      type: Array,
    },
    fakeHoursManned: {
      type: Array,
    },
    boxOpened: {
      type: Object,
    },
  },
  data() {
    return {
      boxOpeningHoursSorted: null,
    };
  },
  created() {
    const { openingHours } = this.box;
    this.boxOpeningHoursSorted = openingHours.sort(this.compare);
  },
  methods: {
    weekdayName(weekdayNumber) {
      const days = [
        {
          weekday: 1,
          name: `${this.$t('date.sunday')}`,
        },
        {
          weekday: 2,
          name: `${this.$t('date.monday')}`,
        },
        {
          weekday: 3,
          name: `${this.$t('date.tuesday')}`,
        },
        {
          weekday: 4,
          name: `${this.$t('date.wednesday')}`,
        },
        {
          weekday: 5,
          name: `${this.$t('date.thursday')}`,
        },
        {
          weekday: 6,
          name: `${this.$t('date.friday')}`,
        },
        {
          weekday: 7,
          name: `${this.$t('date.saturday')}`,
        },
      ];
      return days.filter((day) => weekdayNumber === day.weekday).map((day) => day.name);
    },

    compare(a, b) {
      if (a.weekday < b.weekday) {
        return -1;
      }
      if (a.weekday > b.weekday) {
        return 1;
      }
      return 0;
    },
  },
};
</script>
